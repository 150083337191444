
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatableLeft.vue";
import SearchBar from "@/components/search-bars/SearchBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AssessorListDrawer from "@/layout/header/partials/assessor/AssessorListEditDrawer2.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin";
import axios from 'axios';
import { ElNotification } from 'element-plus';
import moment from 'moment';
import Multiselect from '@vueform/multiselect';


export default defineComponent({
  mixins: [apiEndpoint],
  name: "pending-assessment-list",
  components: {
    Form,
    Datatable,
    SearchBar,
    AssessorListDrawer,
    Swal,
    ElNotification,
    Multiselect,
  },
  data() {
    return {
      moment: '' as any,
      api_url: "",
      load: false,
      loading: false,
      batchInfo: [],
      courseList: [] as any,
      instituteList: [] as any,
       optionsTranche: [] as any,
      tranches_info: [] as any,
      courseInfoData: [] as any,
      entityInfos: [],
      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      division_data: [] as any,
      districts_data: [] as any,
       optionsTPartner: [] as any,
       batch: {
        entity_id: [] as any,
        tranche_id: [] as any,
        institute_id: '',
        course_id: '',
        division: '',
        district: '',
      },
      tableHeader: [
        {
          name: "SL",
          key: "sl",
          sortable: true,
        },
        {
          name: "Tranche",
          key: "tranche",
          sortable: true,
        },
        {
          name: "Training Partner",
          key: "training_partner",
          sortable: true,
        },
        {
          name: "Institute",
          key: "ti",
          sortable: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "Batch No",
          key: "batch",
          sortable: true,
        },
      ],
       componentKey: 0,
      showDetails:false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      tableData:[] as any,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getPendingAssessment();
       this.moment = moment;
    await this.getTranche();
    await this.getDivision();
    await this.getAssociation();
    await this.getDistrict();
    await this.getTrainingInstitute();

    
  },
   watch: {
    "batch.entity_id": {
      handler: "getTrainingInstitute", // Call the method when batch.entity_id changes
      immediate: false, // Set this to false if you want to trigger on initial mount
    },
  },
  methods: {
     async getDivision() {
      await ApiService.get('configurations/geo_division/list')
        .then((response) => {
          this.division_data = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrict() {
      await ApiService.get(
        'configurations/geo_district/list?division=' + this.batch.division
      )
        .then((response) => {
          this.districts_data = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async courseInfo() {
      this.loading = true;
      let entity_id = this.batch.entity_id;
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
        '?entity_id=' +
        entity_id +
        '&tranche=' +
        this.batch.tranche_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          console.log(response);
          this.courseList = response.data.data;
          this.courseInfoData = true;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
        this.batch.entity_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.instituteList = response.data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();
      let entity_id = this.batch.entity_id;
      let institute_id = this.batch.institute_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_id = VueCookieNext.getCookie("_institute_info_id");
      }
      formData.set('entity_id', entity_id);
      formData.set('institute_info_id', institute_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('course_info_id', this.batch.course_id);
      formData.set('division', this.batch.division);
      formData.set('district', this.batch.district);
      
      await ApiService.post('assessment/pending_assesment_list_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
             ElNotification.closeAll();
          });
        })
        .catch(({ response }) => {
          console.log(response);
          ElNotification.closeAll();
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();
      let entity_id = this.batch.entity_id;
      let institute_id = this.batch.institute_id;
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
         entity_id = VueCookieNext.getCookie("_entity_id");
      }
      if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
        institute_id = VueCookieNext.getCookie("_institute_info_id");
      }
     formData.set('entity_id', entity_id);
      formData.set('institute_info_id', institute_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('course_info_id', this.batch.course_id);
      formData.set('division', this.batch.division);
      formData.set('district', this.batch.district);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/assessment/pending_assesment_list_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'pendingAssesment Report.xlsx');
          document.body.appendChild(link);
          link.click();
          ElNotification.closeAll();
        })
        .catch((response) => {
          console.log(response);
          ElNotification.closeAll();
        });
    },
    
      async getPendingAssessment() {
      this.loading = true;
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }

      ApiService.get(
        'assessment/pending-list?entity_id=' + this.batch.entity_id +
        '&tranche_id=' +
        this.batch.tranche_id +
        '&course_id=' +
        this.batch.course_id +
        '&institute_id=' +
        institute_info_id +
        '&division=' +
        this.batch.division +
        '&district=' +
        this.batch.district
      )
        .then((response) => {
           this.componentKey += 1;
          this.batchInfo = response.data.data;
          this.showDetails = true;
          this.loading = false;
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
        });
    },
   
    
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
